import cx from "classnames";
import Image from "next/image";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { useI18n } from "utils/with-i18n.utils";

import { Divider } from "./Divider";
import { NavLink } from "./NavLink";
import PinkLightning from "./pink-lightning.svg";

type NavLinksProps = {
  onClick?: (href: string) => void;
};

export const NavLinks = ({ onClick }: NavLinksProps) => {
  const i18n = useI18n();
  const { isBlackFriday } = useSettings();

  return (
    <>
      <ul className="space-y-5 w-full lg:flex lg:space-y-0 lg:max-w-fit">
        <li className="justify-center w-full lg:max-w-fit">
          <div className="px-6 w-full lg:flex lg:justify-between lg:items-center lg:px-0 lg:max-w-fit">
            {isBlackFriday ? (
              <Image
                src="/images/christmas/blue-gift.svg"
                width={16}
                height={16}
                alt="blue gift icon"
              />
            ) : (
              <PinkLightning className="hidden lg:flex" />
            )}

            <NavLink
              className={cx("lg:pr-6 lg:pl-2 pt-[0.12rem] text-red-discount", {
                "uppercase font-bold text-transparent bg-clip-text bg-christmas":
                  isBlackFriday,
              })}
              href={isBlackFriday ? "/smartphone/promos" : "/smartphone/promos"}
              onClickLink={onClick}
            >
              {i18n.t(
                isBlackFriday ? "navbar.blackFriday" : "navbar.ourGoodDeals",
              )}
            </NavLink>
          </div>
        </li>
        <li className="hidden lg:block">
          <Divider />
        </li>
        <li className="justify-center">
          <NavLink
            className="px-6 pt-[0.12rem] "
            href="/smartphone/nouveau"
            onClickLink={onClick}
          >
            {i18n.t("navbar.ourNovelties")}
          </NavLink>
        </li>
        <li className="hidden lg:block">
          <Divider />
        </li>
        <li className="justify-center">
          <NavLink
            className="px-6 pt-[0.12rem] "
            href="/smartphone/iphone"
            onClickLink={onClick}
          >
            {i18n.t("navbar.iPhone")}
          </NavLink>
        </li>
        <li className="hidden lg:block">
          <Divider />
        </li>
        <li>
          <NavLink
            className="px-6 pt-[0.12rem]"
            href="/smartphone/android"
            onClickLink={onClick}
          >
            {i18n.t("navbar.samsung")}
          </NavLink>
        </li>
        <li className="hidden lg:block">
          <Divider />
        </li>
        <li>
          <NavLink
            className="px-6 pt-[0.12rem]"
            href="/compare"
            onClickLink={onClick}
          >
            {i18n.t("navbar.comparator")}
          </NavLink>
        </li>
        <li className="hidden lg:block">
          <Divider />
        </li>
        <li>
          <NavLink
            className="px-6 pt-[0.12rem] "
            href="/club"
            onClickLink={onClick}
          >
            {i18n.t("navbar.club")}
          </NavLink>
        </li>
        <li className="hidden lg:block">
          <Divider />
        </li>
        <li>
          <NavLink
            className="px-6 pt-[0.12rem]"
            href="https://aide.mobile.club/"
            onClickLink={onClick}
          >
            {i18n.t("navbar.needHelp")}
          </NavLink>
        </li>
      </ul>
    </>
  );
};
