import React, { PropsWithChildren } from "react";

import {
  SettingsProvider,
  SettingsProviderProps,
} from "shared/SettingsProvider";
import { withApp } from "utils/with-app.utils";

import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import { NavbarBusiness } from "./NavbarBusiness";

type LayoutProps = {
  withBanner?: boolean;
  settings?: Partial<SettingsProviderProps>;
};

export const Layout = ({
  children,
  withBanner,
  settings,
}: PropsWithChildren<LayoutProps>) => {
  return (
    <SettingsProvider
      isBlackFriday={!!settings?.isBlackFriday && !settings?.isBusiness}
      isBusiness={!!settings?.isBusiness}
    >
      {!!settings?.isBusiness ? (
        <NavbarBusiness />
      ) : (
        <Navbar withBanner={withBanner} />
      )}

      {children}

      <Footer />
    </SettingsProvider>
  );
};

export const AppLayout = withApp(Layout, [], true);
